body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.picker input {
    min-width: 200px;
}

#squads > ul > div > li > p {
    display: none;
}

.competitions tr:nth-child(even),
.competitions li:nth-of-type(2n+2) {
    background: #cfeaff
}


.competitions tr:nth-child(odd),
.competitions li:nth-of-type(2n+1) {
    background: #FFF
}

.link-button > * {
    text-align: left !important;
    display: block !important;
}

.center {
    text-align: center !important;
}
